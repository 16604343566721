<template>
  <div>
    <h2 class="pageTitle pt-5 mb-8">デフォルト予診票確認</h2>
    <div class="mx-6">
      <div class="mb-6 blue--text text--darken-3">
        この内容でよろしければ、下の確認ボタンを押してください。
      </div>
      <real-prediction :data="predictionData"></real-prediction>
      <!-- <v-textarea
        outlined
        label="メモ欄"
        dense
        class="mt-6"
        v-model="memo"
      ></v-textarea> -->
      <v-row class="my-6 mx-6" justify="end">
        <v-btn large @click="returnPushed" class="mr-4">戻る</v-btn>
        <v-btn color="primary" large @click="confirmPushed">確認</v-btn>
      </v-row>
    </div>
  </div>
</template>

<script>
import realPrediction from "@/components/realPrediction.vue";
import firebase from "firebase";

export default {
  components: {
    realPrediction,
  },
  data: () => ({
    id: "",
    predictionData: {},
    memo: "",
  }),
  created() {
    if (this.$router.currentRoute.query.id) {
      this.id = this.$router.currentRoute.query.id;
    }
    this.predictionData = this.$store.getters.predictionData;
  },
  methods: {
    returnPushed() {
      if (this.id) {
        this.$router.push({
          name: "makePrediction",
          query: { id: this.id, return: true },
        });
      } else {
        this.$router.push({
          name: "makePrediction",
          query: { return: true },
        });
      }
    },
    confirmPushed() {
      if (this.id) {
        firebase
          .firestore()
          .collection("pre_exam_data")
          .doc(this.id)
          .update({
            title: this.predictionData.title,
            questions: this.predictionData.questions,
            memo: this.memo,
            updated: new Date(),
          })
          .then(() => {
            this.$router.push({
              name: "completePrediction",
              query: { edit: "edit" },
            });
          });
      } else {
        firebase
          .firestore()
          .collection("pre_exam_data")
          .add({
            title: this.predictionData.title,
            questions: this.predictionData.questions,
            memo: this.memo,
            updated: new Date(),
          })
          .then(() => {
            this.$router.push({ name: "completePrediction" });
          });
      }
    },
  },
  watch: {},
  filters: {},
};
</script>
<style>
.pageTitle {
  border-bottom: solid 3px rgba(0, 0, 0, 0.87);
}
</style>
